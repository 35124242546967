<template>
    <div class="modalx"
        v-bind:style="{ 'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')'}"
        loading="lazy">
        <div class="overlayy"></div>
        <div class="content-modalx">
            <div class="info_modalx">
                <div class="elementor-image img">
                    <img decoding="async" src="assets/wp-content/uploads/2023/02/blank.png" title="QW-009" alt="QW-009" />
                </div>
                <div class="lws-mempelai" v-if="client.hajat == 'pria'" style="color: #AACFD0;">
                    {{ client.nama_pria }} &amp; {{ client.nama_wanita }}
                </div>
                <div class="lws-mempelai" v-if="client.hajat == 'wanita'" style="color: #AACFD0;">
                    {{ client.nama_wanita }} &amp; {{ client.nama_pria }}
                </div>
                <div class="lws-dear" style="font-family: 'Dancing Script', cursive; font-size: 18px; color: #AACFD0;">
                    {{ config.language === 1 ? "To Mr./Mrs./Sir/Madam" : 'Kepada Bapak/Ibu/Saudara/i' }}
                </div>
                <div class="lws-name" style="font-size: 30px; color: #AACFD0;" >
                    {{guest.nama}}
                </div>
                <div v-if="showName" class="lws-name" style="font-size: 30px; color: #AACFD0;">
                    {{name}}
                </div>
                
                <div class="lws-button-wrapper">
                    <button class="elementor-button" @click="close" style="color: #79A8A9; border-color: #79A8A9;">{{ config.language === 1 ? "Open Invitation" : 'Buka Undangan' }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: ['client', 'guest', 'config'],
    methods: {
        close() {
            this.$emit('close');
        },
    },
    computed: {
        name() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                if (toParam.includes('-')) {
                return '';
                } else {
                return toParam;
                }
            } else {
                return '';
            }
        },
        showName() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                return !toParam.includes('-');
            } else {
                return false;
            }
        },
    },
};
</script>